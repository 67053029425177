import request from "@/utils/request";
//发送验证码
export function sendMessageCode(data) {
  return request({
    url: `sendMessageCode?phoneNumber=${data.phone}`,
    method: "post",
  });
}
//校验验证码
export function checkMessageCode(phone, messageCode) {
    return request({
        url: `user/checkMessageCode?phoneNumber=${phone}&verifyCode=${messageCode}`,
        method: "post",
    });
}
//手机号注册
export function registerByPhone(verifyCode, data) {
  return request({
      url: `registerByPhone/withUrbanBuilding?verifyCode=${verifyCode}`,
      method: "post",
      data,
  });
}
